import React from 'react';
import classNames from 'classnames'
import style from './DownloadBtn.module.scss'

export const DownloadBtn = ({ type, handle = () => {} }) => {
	const handleClick = () => {
		if (type !== 'downloaded' || type !== 'notUploaded') {
			handle()
		}
	}

	return (
		<button type='button' className={style.btn} onClick={handleClick}>
			<svg
				version='1.1'
				viewBox='0 0 256 256'
				className={classNames({
					[style.download]: true,
					[style.download__head]: type === 'head',
					[style.download__notUploaded]: type === 'notUploaded',
				})}
			>
				<g
					className={style.download__G}
					transform='translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)'
				>
					<path
						d='M 69.473 36.819 c -2.112 -1.781 -5.266 -1.511 -7.046 0.599 L 50 52.155 V 5 c 0 -2.761 -2.238 -5 -5 -5 c -2.761 0 -5 2.239 -5 5 v 47.155 L 27.573 37.418 c -1.781 -2.111 -4.935 -2.378 -7.045 -0.599 c -2.111 1.78 -2.379 4.935 -0.599 7.045 l 21.25 25.199 c 0.075 0.088 0.156 0.168 0.236 0.251 c 0.028 0.029 0.054 0.059 0.083 0.088 c 0.2 0.196 0.416 0.372 0.643 0.531 c 0.044 0.031 0.088 0.061 0.133 0.091 c 0.23 0.15 0.471 0.282 0.722 0.392 c 0.05 0.022 0.101 0.04 0.151 0.061 c 0.264 0.105 0.534 0.193 0.813 0.252 c 0.035 0.007 0.071 0.011 0.106 0.018 c 0.306 0.058 0.618 0.094 0.935 0.094 c 0.317 0 0.628 -0.036 0.934 -0.094 c 0.035 -0.007 0.071 -0.01 0.106 -0.018 c 0.279 -0.059 0.549 -0.147 0.812 -0.252 c 0.052 -0.021 0.103 -0.039 0.154 -0.062 c 0.25 -0.11 0.49 -0.241 0.719 -0.39 c 0.047 -0.03 0.092 -0.061 0.137 -0.093 c 0.226 -0.158 0.441 -0.333 0.64 -0.528 c 0.03 -0.029 0.057 -0.061 0.086 -0.091 c 0.079 -0.082 0.161 -0.161 0.234 -0.249 l 21.249 -25.199 C 71.852 41.753 71.583 38.599 69.473 36.819 z'
						className={classNames({
							[style.download__P]: true,
							[style.download__P__head]: type === 'head',
							[style.download__P__downloaded]: type === 'downloaded',
							[style.download__P__notUploaded]: type === 'notUploaded',
						})}
						transform=' matrix(1 0 0 1 0 0) '
						strokeLinecap='round'
					/>
					<path
						d='M 75.787 90 H 14.212 c -2.761 0 -5 -2.238 -5 -5 s 2.239 -5 5 -5 h 61.575 c 2.762 0 5 2.238 5 5 S 78.549 90 75.787 90 z'
						className={classNames({
							[style.download__P]: true,
							[style.download__P__head]: type === 'head',
							[style.download__P__downloaded]: type === 'downloaded',
							[style.download__P__notUploaded]: type === 'notUploaded',
						})}
						transform=' matrix(1 0 0 1 0 0) '
						strokeLinecap='round'
					/>
				</g>
			</svg>
		</button>
	)
}

import React, { useContext, useState } from 'react';
import axios from 'axios';
import { getTranslation, Language } from '../../language';
import { UploadProgressModal } from '../UploadProgressModal';
import { ExitBtn } from '../../c_ui/ExitBtn';
import { Input } from '../../c_ui/Input/Input';
import { Btn } from '../../c_ui/Btn';
import style from './ResearcherModal.module.scss'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const ResearcherModal = ({ data, exitHandle, userName }) => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const [file, setFile] = useState([])
	const [progress, setProgress] = useState([])
	const [isLoader, setIsLoader] = useState(false)

	const handleUpload = async event => {
		event.preventDefault()

		const initialProgress = {
			fileName: file[0].name,
			progress: 0,
		}
		setProgress([initialProgress])
		setIsLoader(true)
		await uploadFile(file[0])

		setFile([])
		setIsLoader(false)
		exitHandle(false)
		document.body.style.overflow = ''
	}
	
	const uploadFile = async file => {
		const formData = new FormData()
		formData.append('id', data._id)
		formData.append('date', data.post_date)
		formData.append('resource', data.resource)
		formData.append('researcher_name', userName)
		formData.append('file', file)
		
		try {
			// завантаження файлу на сервер
			await axios.post(`${BASE_URL}/uploadLargeFile`, formData, {
				onUploadProgress: event => {
					const total = event.total // загальний розмір файлу
					const currentProgress = Math.round((event.loaded / total) * 100)

					setProgress(prevProgress =>
						prevProgress.map(p =>
							p.fileName === file.name // оновлюємо перший файл
								? { ...p, progress: currentProgress }
								: p
						)
					)
				},
			})
		} catch (err) {
			console.error('Upload failed:', err)
		}
	}

	return (
		<div className={style.area}>
			<form onSubmit={handleUpload} className={style.modal}>
				<span className={style.exit}>
					<ExitBtn exitPropHandle={exitHandle} />
				</span>

				<div>
					<p className={style.text}>
						{TEXT.researcher_modal_recource}: @{data.resource}
					</p>
					<p className={style.text}>
						{TEXT.researcher_modal_date}: {data.post_date.replaceAll('_', '.')}
					</p>
					<p className={style.text}>
						{TEXT.researcher_modal_file_name}: {data.file_name}
					</p>
					<p className={style.text}>
						{TEXT.researcher_modal_message_body}: {data.message_content}
					</p>
				</div>

				<Input inputType={'file_one'} setInputValue={setFile} />

				<Btn type='submit' text={TEXT.researcher_modal_btn_upload} />
			</form>

			{isLoader && <UploadProgressModal progress={progress} />}
		</div>
	)
}

import React, { useContext, useState } from 'react'
import axios from 'axios'
import { getTranslation, Language } from '../../language'
import { Loader } from '../Loader'
import { ExitBtn } from '../../c_ui/ExitBtn'
import style from './InspectorModal.module.scss'
import { DownloadBtn } from '../../c_ui/DownloadBtn'
import { ProcessorIcon } from '../../c_ui/ProcessorIcon'
import { ResearcherIcon } from '../../c_ui/ResearcherIcon'

const BASE_URL = process.env.REACT_APP_BASE_URL

const filesType = {
	filtered: 'filtered',
	unfiltered: 'unfiltered',
	ignored: 'ignored',
}

export const InspectorModal = ({
	data = [],
	exitHandle,
	inspectoredFilesType,
}) => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const [usedIds, setUsedIds] = useState([])
	const [loader, setLoader] = useState(false)

	const filteredHeaders = [
		TEXT.inspector_modal_name,
		TEXT.inspector_modal_size,
		<ProcessorIcon />,
		TEXT.inspector_modal_comment,
		TEXT.inspector_modal_rows,
		<DownloadBtn type={'head'} />,
	]

	const unfilteredHeaders = [
		TEXT.inspector_modal_name,
		TEXT.inspector_modal_size,
		<ResearcherIcon />,
		TEXT.inspector_modal_comment,
		<DownloadBtn type={'head'} />,
	]

	const ignoredHeaders = [
		TEXT.inspector_modal_name,
		TEXT.inspector_modal_size,
		<ResearcherIcon />,
		TEXT.inspector_modal_comment,
		<ProcessorIcon />,
		TEXT.inspector_modal_comment,
		<DownloadBtn type={'head'} />,
	]

	const handleDownload = async currentData => {
		try {
			setLoader(true)
			let response
			if (inspectoredFilesType === filesType.filtered) {
				console.log('filter')
				response = await axios.post(
					`${BASE_URL}/downloadFile/${currentData.post_date}/${currentData.resource}/filtered/${currentData.filtered_file_name}`,
					null,
					{ responseType: 'blob' }
				)
			} else if (
				inspectoredFilesType === filesType.unfiltered ||
				inspectoredFilesType === filesType.ignored
			) {
				console.log('orig')
				response = await axios.post(
					`${BASE_URL}/downloadFile/${currentData.post_date}/${currentData.resource}/original/${currentData.file_name}`,
					null,
					{ responseType: 'blob' }
				)
			}

			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url

			// запис імені файлу
			if (inspectoredFilesType === filesType.filtered) {
				link.setAttribute('download', currentData.filtered_file_name)
			}
			if (
				inspectoredFilesType === filesType.unfiltered ||
				inspectoredFilesType === filesType.ignored
			) {
				link.setAttribute('download', currentData.file_name)
			}

			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)

			if (data.length === 1) {
				exitHandle(false)
				document.body.style.overflow = ''
			} else {
				setLoader(false)
				setUsedIds(prevIds => [...prevIds, currentData._id])
			}
		} catch (err) {
			console.log(err)
		}
	}

	// console.log(inspectoredFilesType)

	return (
		<div className={style.area}>
			<div className={style.modal}>
				<span className={style.exit}>
					<ExitBtn exitPropHandle={exitHandle} />
				</span>

				<div className={style.tableContainer}>
					<table>
						<thead>
							<tr>
								{inspectoredFilesType === filesType.filtered &&
									filteredHeaders.map((header, i) => (
										<th key={i} className={style.th}>
											{header}
										</th>
									))}
								{inspectoredFilesType === filesType.unfiltered &&
									unfilteredHeaders.map((header, i) => (
										<th key={i} className={style.th}>
											{header}
										</th>
									))}
								{inspectoredFilesType === filesType.ignored &&
									ignoredHeaders.map((header, i) => (
										<th key={i} className={style.th}>
											{header}
										</th>
									))}
							</tr>
						</thead>

						<tbody>
							{data.map((file, i) => (
								<tr key={i}>
									{/* Name */}
									{file.is_filtered &&
									inspectoredFilesType === filesType.filtered ? (
										<td title={file.filtered_file_name} className={style.td}>
											{file.filtered_file_name}
										</td>
									) : (
										<td title={file.file_name} className={style.td}>
											{file.file_name}
										</td>
									)}

									{/* Size */}
									<td title={file.file_size} className={style.td}>
										{file.file_size}
									</td>

									{/* Researcher */}
									{(inspectoredFilesType === filesType.unfiltered ||
										inspectoredFilesType === filesType.ignored) &&
										file.researcher && (
											<td className={style.td}>{file.researcher}</td>
										)}

									{/* Researcher comment */}
									{inspectoredFilesType === filesType.unfiltered ? (
										file.researcher_comment ? (
											<td className={style.td}>{file.researcher_comment}</td>
										) : (
											<td className={style.td}></td>
										)
									) : (
										inspectoredFilesType === filesType.ignored && (
											<td className={style.td}>{file.researcher_comment}</td>
										)
									)}

									{/* Processor */}
									{(inspectoredFilesType === filesType.filtered ||
										inspectoredFilesType === filesType.ignored) &&
										file.processor && (
											<td className={style.td}>{file.processor}</td>
										)}

									{/* Processor comment */}
									{inspectoredFilesType === filesType.filtered ? (
										file.processor_comment ? (
											<td className={style.td}>{file.processor_comment}</td>
										) : (
											<td className={style.td}></td>
										)
									) : (
										inspectoredFilesType === filesType.ignored && (
											<td className={style.td}>{file.ignore_comment}</td>
										)
									)}

									{/* Filtered rows */}
									{file.processed_rows && (
										<td className={style.td}>{file.processed_rows}</td>
									)}

									{/* Download */}
									<td title={'Download'} className={style.td}>
										{file.is_uploaded ? (
											<DownloadBtn
												type={usedIds.includes(file._id) ? 'downloaded' : ''}
												handle={() => handleDownload(file)}
											/>
										) : (
											<DownloadBtn type={'notUploaded'} />
										)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			{loader && <Loader />}
		</div>
	)
}

import React, { useContext, useState } from 'react'
import style from './ProcessorModal.module.scss'
import { Btn } from '../../c_ui/Btn'
import { ExitBtn } from '../../c_ui/ExitBtn'
import axios from 'axios'
import { Input } from '../../c_ui/Input/Input'
import { getTranslation, Language } from '../../language'
import { Loader } from '../Loader'
import { UploadProgressModal } from '../UploadProgressModal'

const BASE_URL = process.env.REACT_APP_BASE_URL

const choose = {
	upload: 'upload',
	ignore: 'ignore',
}

export const ProcessorModal = ({ data, exitHandle, userName }) => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const [isChoosed, setIsChoosed] = useState('')
	const [file, setFile] = useState([])
	const [rowsCount, setRowsCount] = useState()
	const [comment, setComment] = useState('')
	const [progress, setProgress] = useState([])
	const [isLoader, setIsLoader] = useState(false)

	const handleChooseDownload = async () => {
		try {
			const response = await axios.post(
				`${BASE_URL}/downloadFile/${data.post_date}/${data.resource}/original/${data.file_name}`,
				null,
				{ responseType: 'blob' }
			)

			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url
			console.log(url)

			link.setAttribute('download', data.file_name)

			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)

			exitHandle(false)
			document.body.style.overflow = ''
		} catch (err) {
			console.log(err)
		}
	}

	const handleChooseUpload = () => {
		setIsChoosed(choose.upload)
	}
	const handleChooseIgnore = () => {
		setIsChoosed(choose.ignore)
	}

	const handleUpload = async event => {
		event.preventDefault()

		const initialProgress = {
			fileName: file[0].name,
			progress: 0,
		}

		setProgress([initialProgress])
		setIsLoader(true)
		await uploadFile(file[0])

		setFile([])
		setIsLoader(false)
		exitHandle(false)
		document.body.style.overflow = ''
	}

	const uploadFile = async file => {
		const formData = new FormData()
		formData.append('id', data._id)
		formData.append('date', data.post_date)
		formData.append('resource', data.resource)
		formData.append('processor_name', userName)
		formData.append('rows_count', rowsCount)
		formData.append('comment', comment)
		formData.append('new_file_name', file.name)
		formData.append('file', file)

		try {
			// завантаження файлу на сервер
			await axios.post(`${BASE_URL}/uploadFilteredFile`, formData, {
				onUploadProgress: event => {
					const total = event.total // загальний розмір файлу
					const currentProgress = Math.round((event.loaded / total) * 100)

					setProgress(prevProgress =>
						prevProgress.map(p =>
							p.fileName === file.name // оновлюємо перший файл
								? { ...p, progress: currentProgress }
								: p
						)
					)
				},
			})
		} catch (err) {
			console.error('Upload failed:', err)
		}
	}

	const handleIgnore = async event => {
		event.preventDefault()

		await axios.post(`${BASE_URL}/ignoreFile`, {
			userId: data._id,
			processor_name: userName,
			comment: comment,
		})
		exitHandle(false)
		document.body.style.overflow = ''
	}

	return (
		<div className={style.area}>
			<div className={style.modal}>
				<span className={style.exit}>
					<ExitBtn exitPropHandle={exitHandle} />
				</span>

				{!isChoosed && (
					<span className={style.btns}>
						<Btn
							text={TEXT.processor_modal_btn_download}
							handle={handleChooseDownload}
						/>
						<Btn
							text={TEXT.processor_modal_btn_upload}
							handle={handleChooseUpload}
						/>
						<Btn
							text={TEXT.processor_modal_btn_ignore}
							handle={handleChooseIgnore}
						/>
					</span>
				)}

				{isChoosed === choose.upload && (
					<form onSubmit={handleUpload} className={style.form}>
						<span className={style.row}>
							<p className={style.text}>{TEXT.processor_add_file}</p>

							<Input inputType={'file_one'} setInputValue={setFile} />
						</span>

						<span className={style.row}>
							<p className={style.text}>{TEXT.processor_modal_rows_count}</p>

							<Input
								inputType={'number'}
								inputPlaceholder={TEXT.processor_modal_rows_count_placeholder}
								inputValue={rowsCount}
								setInputValue={setRowsCount}
							/>
						</span>

						<span className={style.row}>
							<p className={style.text}>
								{TEXT.processor_modal_upload_comment}
							</p>

							<textarea
								value={comment}
								placeholder={TEXT.processor_modal_upload_comment_placeholder}
								onChange={e => setComment(e.target.value)}
								className={style.input}
							/>
						</span>

						<Btn type='submit' text={TEXT.processor_modal_btn_upload} />
					</form>
				)}

				{isChoosed === choose.ignore && (
					<form onSubmit={handleIgnore} className={style.form}>
						<span className={style.row}>
							<p className={style.text}>
								{TEXT.processor_modal_ignore_comment}
							</p>

							<textarea
								value={comment}
								placeholder={TEXT.processor_modal_ignore_comment_placeholder}
								onChange={e => setComment(e.target.value)}
								className={style.input}
								required
							/>
						</span>

						<Btn type='submit' text={TEXT.processor_modal_btn_ignore} />
					</form>
				)}
			</div>

			{isLoader && <UploadProgressModal progress={progress} />}
		</div>
	)
}

import React from 'react'
import style from './Input.module.scss'

export const Input = ({
	inputType,
	inputPlaceholder = '',
	inputValue,
	selectedValue,
	setInputValue,
	minValue,
	maxValue,
	optionalHadle = () => {},
}) => {
	const maxDate = new Date()
		.toLocaleString()
		.slice(0, 10)
		.split('.')
		.reverse()
		.join('-')

	const handleCheckNumberAndUpdate = event => {
		const regExp = /^\d*$/
		const value = event.target.value
		
		if (regExp.test(value)) {
			setInputValue(value)
		}
	}

	const handleCheckStringAndUpdate = event => {
		const regExp = /^[A-Za-z0-9_!@№#%$&*()|.]*$/
		const value = event.target.value

		if (regExp.test(value)) {
			setInputValue(value)
		}
	}
	
	switch (inputType) {
		case 'date':
			return (
				<input
					type='date'
					value={inputValue}
					max={maxValue || maxDate}
					min={minValue || undefined}
					onChange={e => setInputValue(e.target.value)}
					className={style.input}
					required
				/>
			)

		case 'file':
			return (
				<input
					type='file'
					multiple
					onChange={e => setInputValue(Array.from(e.target.files))}
					required
				/>
			)

		case 'file_one':
			return (
				<input
					type='file'
					onChange={e => setInputValue(Array.from(e.target.files))}
					required
				/>
			)

		case 'password':
			return (
				<input
					type='password'
					placeholder={inputPlaceholder}
					value={inputValue}
					onChange={handleCheckStringAndUpdate}
					className={style.input}
					onKeyDown={optionalHadle}
					required
				/>
			)

		case 'radio':
			return (
				<input
					type='radio'
					value={inputValue}
					checked={inputValue === selectedValue}
					onChange={() => setInputValue(inputValue)}
					hidden
				/>
			)

		case 'number':
			return (
				<input
					type='number'
					min='0'
					placeholder={inputPlaceholder}
					value={inputValue}
					onChange={handleCheckNumberAndUpdate}
					className={style.input}
					// onKeyDown={optionalHadle}
					required
				/>
			)

		default:
			return (
				<input
					type='text'
					placeholder={inputPlaceholder}
					value={inputValue}
					onChange={handleCheckStringAndUpdate}
					className={style.input}
					onKeyDown={optionalHadle}
					required
				/>
			)
	}
}

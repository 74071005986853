import React from 'react'
import style from './Footer.module.scss'

export const Footer = () => {
	return (
		<div className={style.footer}>
			Created by Uliton &copy;
		</div>
	)
}

import React, { useContext, useState } from 'react'
import style from './FilesOption.module.scss'
import { getTranslation, Language } from '../../language'
import axios from 'axios'
import { InspectorModal } from '../InspectorModal'
import { Input } from '../../c_ui/Input/Input'
import { Btn } from '../../c_ui/Btn'
import { Loader } from '../Loader'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const FilesOption = () => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [tableData, setTableData] = useState([])
	const [filesData, setFilesData] = useState([])
	const [modalInfo, setModalInfo] = useState()
	const [isLoader, setIsLoader] = useState(false)

	const headers = [
		TEXT.inspector_table_date,
		TEXT.inspector_table_resource,
		TEXT.inspector_table_filtered_files_count,
		TEXT.inspector_table_filtered_rows_count,
		TEXT.inspector_table_unfiltered_files_count,
		TEXT.inspector_table_ignored_files_count,
	]

	const checkFiles = async event => {
		event.preventDefault()
		setIsLoader(true)

		const start = new Date(startDate)
		const end = new Date(endDate)
		const result = []
		const formatDate = date => {
			const year = date.getFullYear()
			const month = String(date.getMonth() + 1).padStart(2, '0')
			const day = String(date.getDate()).padStart(2, '0')
			return `${year}_${month}_${day}`
		}

		let currentDate = start
		while (currentDate <= end) {
			result.push(formatDate(currentDate))
			currentDate.setDate(currentDate.getDate() + 1)
		}

		const files = await axios.post(`${BASE_URL}/getAllUploadFiles`, {
			targetDate: result,
		})

		if (files) {
			const dateAndResources = {}

			for (const file of files.data) {
				if (!dateAndResources[file.post_date]) {
					dateAndResources[file.post_date] = []
				}

				if (!dateAndResources[file.post_date].includes(file.resource)) {
					dateAndResources[file.post_date].push(file.resource)
				}
			}

			const tableRows = []
			for (const [key, values] of Object.entries(dateAndResources)) {
				values.forEach(value => {
					tableRows.push({ tableDate: key, tableResource: value })
				})
			}

			setTableData(tableRows)
			setFilesData(files.data)
		}

		setIsLoader(false)
	}

	return (
		<>
			<form onSubmit={checkFiles} className={style.row}>
				<span className={style.row__part}>
					<p className={style.text}>{TEXT.from}</p>

					<Input
						inputType={'date'}
						inputValue={startDate}
						setInputValue={setStartDate}
						maxValue={endDate}
					/>
				</span>

				<span className={style.row__part}>
					<p className={style.text}>{TEXT.to}</p>

					<Input
						inputType={'date'}
						inputValue={endDate}
						setInputValue={setEndDate}
						minValue={startDate}
					/>
				</span>

				<Btn type='submit' text={TEXT.check} />
			</form>
			<table>
				<thead>
					<tr>
						{headers.map((header, i) => (
							<th key={i} className={style.th}>
								{header}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{tableData.map((data, i) => (
						<tr key={i}>
							{/* Date */}
							{data.tableDate ? (
								<td className={style.td}>
									<span>{data.tableDate}</span>
								</td>
							) : (
								<td className={style.td}></td>
							)}

							{/* Resource */}
							{data.tableResource ? (
								<td className={style.td}>
									<span>{data.tableResource}</span>
								</td>
							) : (
								<td className={style.td}></td>
							)}

							{/* Filtered */}
							{filesData.filter(
								file =>
									file.post_date === data.tableDate &&
									file.resource === data.tableResource &&
									file.is_filtered
							).length ? (
								<td className={style.td}>
									<span
										className={style.td__filtered}
										onClick={() => {
											const filteredData = filesData.filter(
												file =>
													file.post_date === data.tableDate &&
													file.resource === data.tableResource &&
													file.is_filtered
											)

											setModalInfo({ data: filteredData, type: 'filtered' })
										}}
									>
										{
											filesData.filter(
												file =>
													file.post_date === data.tableDate &&
													file.resource === data.tableResource &&
													file.is_filtered
											).length
										}
									</span>
								</td>
							) : (
								<td className={style.td}>0</td>
							)}

							{/* Filtered rows */}
							{filesData.filter(
								file =>
									file.post_date === data.tableDate &&
									file.resource === data.tableResource &&
									file.is_filtered
							).length ? (
								<td className={style.td}>
									<span>
										{filesData
											.filter(
												file =>
													file.post_date === data.tableDate &&
													file.resource === data.tableResource &&
													file.is_filtered
											)
											.reduce((acc, file) => acc + file.processed_rows, 0)}
									</span>
								</td>
							) : (
								<td className={style.td}>0</td>
							)}

							{/* Unfiltered */}
							{filesData.filter(
								file =>
									file.post_date === data.tableDate &&
									file.resource === data.tableResource &&
									!file.is_filtered
							).length ? (
								<td className={style.td}>
									<span
										className={style.td__unfiltered}
										onClick={() => {
											const filteredData = filesData.filter(
												file =>
													file.post_date === data.tableDate &&
													file.resource === data.tableResource &&
													!file.is_filtered
											)

											setModalInfo({ data: filteredData, type: 'unfiltered' })
										}}
									>
										{
											filesData.filter(
												file =>
													file.post_date === data.tableDate &&
													file.resource === data.tableResource &&
													!file.is_filtered
											).length
										}
									</span>
								</td>
							) : (
								<td className={style.td}>0</td>
							)}

							{/* Ignored */}
							{filesData.filter(
								file =>
									file.post_date === data.tableDate &&
									file.resource === data.tableResource &&
									file.is_ignored
							).length ? (
								<td className={style.td}>
									<span
										className={style.td__ignored}
										onClick={() => {
											const filteredData = filesData.filter(
												file =>
													file.post_date === data.tableDate &&
													file.resource === data.tableResource &&
													file.is_ignored
											)

											setModalInfo({ data: filteredData, type: 'ignored' })
										}}
									>
										{
											filesData.filter(
												file =>
													file.post_date === data.tableDate &&
													file.resource === data.tableResource &&
													file.is_ignored
											).length
										}
									</span>
								</td>
							) : (
								<td className={style.td}>0</td>
							)}
						</tr>
					))}
				</tbody>
			</table>

			{modalInfo && (
				<InspectorModal
					data={modalInfo.data}
					exitHandle={setModalInfo}
					inspectoredFilesType={modalInfo.type}
				/>
			)}

			{isLoader && <Loader />}
		</>
	)
}

import React from 'react'
import style from './UploadProgressModal.module.scss'

export const UploadProgressModal = ({ progress }) => {
  // const test = [
	// 	{
	// 		fileName: '123.png',
	// 		progress: 100,
	// 	},
	// 	{
	// 		fileName:
	// 			'Довідка про доходи 3031920110 за I квартал 2024 - II квартал 2024 від 07.11.2024.pdf',
	// 		progress: 80,
	// 	},
	// 	{
	// 		fileName: 'Квитанцiя 45466155174.pdf',
	// 		progress: 70,
	// 	},
	// 	{
	// 		fileName: 'проміжні результати та плани.pdf',
	// 		progress: 50,
	// 	},
	// 	{
	// 		fileName: 'щедрик.pdf',
	// 		progress: 0,
	// 	},
	// 	{
	// 		fileName: 'cpu.svg',
	// 		progress: 0,
	// 	},
	// ]
  console.log(progress)
	return (
		<div className={style.uploader}>
			<div className={style.modal}>
				{progress.map(fileProgress => (
					<div key={fileProgress.fileName} className={style.row}>
						<div className={style.row__info}>
							<p>{fileProgress.fileName}</p>
							<p>{fileProgress.progress}%</p>
						</div>

						<div className={style.line}>
							<div
								style={{
									width: `${fileProgress.progress}%`,
								}}
								className={style.line__done}
							></div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

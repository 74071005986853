import React, { useState, useContext, useEffect } from 'react'
import { getTranslation, Language } from '../../language'
import { DoneIcon } from '../../c_ui/DoneIcon'
import { IgnoreIcon } from '../../c_ui/IgnoreIcon'
import { Btn } from '../../c_ui/Btn'
import style from './UserAdd.module.scss'
import { Input } from '../../c_ui/Input/Input'
import autorizationAnalizer from '../../service/functions/autorizationAnalizer'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL

const roles = {
	researcher: 'researcher',
	processor: 'processor',
	inspector: 'inspector',
}

export const UserAdd = ({ handle }) => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const navigate = useNavigate()
	const [role, setRole] = useState('')
	const [newUserName, setNewUserName] = useState('')
	const [newUserPassword, setNewUserPassword] = useState('')
	const [newUserPasswordRepeat, setNewUserPasswordRepeat] = useState('')

	useEffect(() => {
		setNewUserName('')
		setNewUserPassword('')
		setNewUserPasswordRepeat('')
	}, [role])

	const handleAddUser = async event => {
		event.preventDefault()

		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			navigate('/')
		}

		if (newUserPassword === newUserPasswordRepeat) {
			try {
				await axios.post(`${BASE_URL}/getUserByName`, {
					userName: newUserName,
					userRole: role,
				})
				alert(TEXT.inspector_new_error_alert)
			} catch (e) {
				try {
					await axios.post(`${BASE_URL}/addUser`, {
						userName: newUserName,
						userPassword: newUserPassword,
						userRole: role,
					})

					await handle()
					setNewUserName('')
					setNewUserPassword('')
					setNewUserPasswordRepeat('')
				} catch (error) {
					console.log('addUser error', error)
				}
			}
		}
	}

	const handlerRole = async event => {
		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			navigate('/')
		}

		setRole(event.target.value)
	}

	return (
		<form onSubmit={handleAddUser} className={style.add}>
			<select value={role} onChange={handlerRole} className={style.select}>
				<option value='' selected disabled>
					{TEXT.inspector_new_warrior}
				</option>

				<option value={roles.researcher}>{TEXT.researcher}</option>

				<option value={roles.processor}>{TEXT.processor}</option>

				<option value={roles.inspector}>{TEXT.inspector}</option>
			</select>

			<div className={style.wrapper}>
				<span className={style.inputs}>
					<Input
						inputType={'text'}
						inputPlaceholder={TEXT.inspector_new_login_placeholder}
						inputValue={newUserName}
						setInputValue={setNewUserName}
					/>

					<span className={style.inputs__wrap}>
						<span className={style.inputs__wrap__2}>
							<Input
								inputType={'password'}
								inputPlaceholder={TEXT.inspector_new_password_placeholder}
								inputValue={newUserPassword}
								setInputValue={setNewUserPassword}
							/>

							{newUserPassword && (
								<Input
									inputType={'password'}
									inputPlaceholder={
										TEXT.inspector_new_repeat_password_placeholder
									}
									inputValue={newUserPasswordRepeat}
									setInputValue={setNewUserPasswordRepeat}
								/>
							)}
						</span>

						{newUserPasswordRepeat.length > 0 &&
							(newUserPassword === newUserPasswordRepeat ? (
								<DoneIcon />
							) : (
								<IgnoreIcon />
							))}
					</span>
				</span>

				<Btn type='submit' text={TEXT.researcher_tg_btn_add} />
			</div>
		</form>
	)
}

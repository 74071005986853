
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Language } from './language.js';
import { Header } from './components/Header';
import { Main } from './pages/Main';
import { Researcher } from './pages/Researcher';
import { Processor } from './pages/Processor';
import { Inspector } from './pages/Inspector';
import { Test } from './pages/Test'
import { Footer } from './components/Footer'

const BASE_URL = process.env.REACT_APP_BASE_URL

function App() {
  const [lang, setLang] = useState('ua');
  const navigate = useNavigate();
  const location = useLocation()
  
  useEffect(() => {
    if (!location.pathname.includes('test')) {
      if (localStorage.length) {
        const storedTime = Number(localStorage.getItem('time'));
        const currentTime = new Date().getTime();
  
        if (currentTime - storedTime > 3600000 * 6) {
          localStorage.clear();
          navigate('/')
        } else {
          async function fetchData() {
            const storedUserId = localStorage.getItem('id');
            
            if (storedUserId !== null) {
              try {
                const user = await axios.post(`${BASE_URL}/getUserById`, {
									userId: storedUserId,
								})
      
                if (user) {
                  navigate(user.data.role);
                }
              } catch (err) {
                console.log('/getUserById error')
                navigate('/');
              }
            } else {
              navigate('/')
            }
          }
    
          fetchData();
        }
      }
    }
  }, []);

  return (
		<main className='main'>
			<div className='snowflakes'>
				<div className='snowflake'></div>
				<div className='snowflake'></div>
				<div className='snowflake'></div>

				<div className='snowflake'></div>
				<div className='snowflake'></div>
				<div className='snowflake'></div>

				<div className='snowflake'></div>
				<div className='snowflake'></div>
				<div className='snowflake'></div>

				<div className='snowflake'></div>
				<div className='snowflake'></div>
				<div className='snowflake'></div>
			</div>
			<div className='container'>
				<Header lang={lang} setLang={setLang} />

				<Language.Provider value={lang}>
					<Routes>
						<Route path='/' element={<Main />} />
						<Route path='/researcher' element={<Researcher />} />
						<Route path='/processor' element={<Processor />} />
						<Route path='/inspector' element={<Inspector />} />
						<Route path='/test' element={<Test />} />
					</Routes>
				</Language.Provider>
			</div>

			<Footer />
		</main>
	)
}

export default App;

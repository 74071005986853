import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { getTranslation, Language } from '../../language'
import { ExitBtn } from '../../c_ui/ExitBtn'
import style from './UserInfoModal.module.scss'
import { useNavigate } from 'react-router-dom'
import { Input } from '../../c_ui/Input/Input'
import { Btn } from '../../c_ui/Btn'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const UserInfoModal = ({ exitHandle }) => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const navigate = useNavigate()
	const [userInfo, setUserInfo] = useState()
	const [newUserName, setNewUserName] = useState('')
	const [newUserPassword, setNewUserPassword] = useState('')
	const [errorMessage, setErrorMessage] = useState('')

	useEffect(() => {
		async function fetchData() {
			const storedUserId = localStorage.getItem('id')

			try {
				const user = await axios.post(`${BASE_URL}/getUserById`, {
					userId: storedUserId,
				})

				if (user) {
					setUserInfo(user.data)
				}
			} catch (err) {
				console.log('fetchData func error')
				navigate('/')
			}
		}

		fetchData()
	}, [])

	const handleSubmit = async event => {
		event.preventDefault()
		
		const storedUserId = localStorage.getItem('id')

		const payload = {
			id: storedUserId,
			new_name: newUserName,
			new_password: newUserPassword,
			user_role: userInfo.role
		}

		try {
			const response = await axios.post(`${BASE_URL}/updateUserInfo`, payload)

			if (response.status === 200) {
				setErrorMessage(response.data)
			}
		} catch (error) {
			document.body.style.overflow = ''
			navigate(0)
			// console.error('Update failed:', error)
		}
	}

	return (
		<div className={style.area}>
			<div className={style.modal}>
				<span className={style.exit}>
					<ExitBtn exitPropHandle={exitHandle} />
				</span>

				<div className={style.form}>
					{userInfo && (
						<>
							<p>
								{TEXT.welcome_current_login} - {userInfo.name}
							</p>
							<p>
								{TEXT.welcome_current_password} - {userInfo.password}
							</p>
						</>
					)}

					<form onSubmit={handleSubmit} className={style.form__wrapper}>
						<Input
							inputType={'text'}
							inputPlaceholder={TEXT.welcome_new_login_placeholder}
							inputValue={newUserName}
							setInputValue={setNewUserName}
						/>
						<Input
							inputType={'text'}
							inputPlaceholder={TEXT.welcome_new_password_placeholder}
							inputValue={newUserPassword}
							setInputValue={setNewUserPassword}
						/>

						<Btn type='submit' text={TEXT.welcome_btn_save} />
					</form>

					{errorMessage && <p>{errorMessage}</p>}
				</div>
			</div>
		</div>
	)
}

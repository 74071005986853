import React from 'react';
import style from './ResearcherIcon.module.scss'

export const ResearcherIcon = () => {
	return (
		<svg
			className={style.researcher}
			viewBox='0 0 512.000000 512.000000'
		>
			<g
				transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
				className={style.researcher__G}
				fill='#000000'
				stroke='none'
			>
				<path d='M3980 4824 c-19 -8 -58 -37 -86 -65 l-50 -49 265 -265 265 -265 43 42 c82 80 105 177 63 263 -12 25 -79 100 -168 186 -115 114 -156 148 -188 157 -55 16 -101 15 -144 -4z' />
				<path d='M3665 4660 c-44 -10 -69 -26 -99 -60 -39 -47 -52 -100 -37 -153 11 -39 47 -79 279 -310 260 -258 268 -266 315 -272 128 -19 229 94 197 217 -10 37 -47 78 -263 296 -139 140 -263 259 -277 266 -35 18 -80 24 -115 16z' />
				<path d='M2789 3654 l-691 -691 18 -58 c17 -54 22 -59 69 -79 56 -26 107 -77 126 -130 10 -27 22 -39 47 -47 49 -16 102 -67 128 -123 22 -48 25 -50 87 -66 l65 -17 686 686 686 686 -265 265 -265 265 -691 -691z m549 -4 c2 -21 -38 -65 -242 -268 -223 -223 -246 -243 -265 -233 -46 25 -28 49 217 294 197 198 241 237 262 235 18 -2 26 -10 28 -28z' />
				<path d='M2043 4090 c-546 -47 -1029 -377 -1268 -865 -249 -509 -211 -1092 103 -1561 426 -636 1255 -866 1955 -542 96 44 111 49 135 39 16 -7 112 -96 215 -199 l187 -187 283 280 282 280 -197 201 c-138 139 -198 207 -198 222 0 13 15 53 34 91 47 92 102 252 128 375 19 86 22 132 22 311 0 179 -3 225 -22 311 -25 114 -81 282 -119 357 l-25 50 -180 -180 -181 -181 24 -93 c21 -82 24 -117 24 -269 -1 -159 -3 -184 -28 -273 -121 -439 -480 -744 -936 -797 -423 -50 -857 181 -1061 563 -46 86 -92 216 -111 312 -18 90 -18 310 0 400 66 334 279 616 578 764 252 124 515 150 782 75 l93 -27 179 179 179 179 -76 38 c-220 111 -539 169 -801 147z' />
				<path d='M1968 2833 c-45 -48 -69 -81 -76 -109 -6 -21 -17 -64 -25 -95 l-14 -57 191 -191 191 -191 84 20 c99 24 128 40 200 114 l54 54 -44 11 c-88 22 -90 23 -109 81 -20 59 -55 96 -98 106 -58 12 -69 21 -81 69 -14 54 -55 101 -97 110 -63 14 -71 22 -90 85 l-19 62 -67 -69z' />
				<path d='M1825 2444 c-4 -16 -13 -54 -21 -85 -25 -99 -15 -147 38 -197 44 -41 82 -45 191 -21 53 12 97 25 97 28 0 4 -67 73 -149 155 -148 148 -150 149 -156 120z' />
				<path d='M3975 1256 c-16 -8 -141 -126 -277 -262 -208 -210 -248 -255 -257 -290 -13 -48 -6 -97 20 -140 34 -57 201 -209 263 -239 110 -54 264 -45 366 20 19 13 88 76 152 142 151 153 173 196 173 333 0 134 -24 181 -158 318 -56 57 -116 111 -134 118 -41 18 -109 17 -148 0z' />
			</g>
		</svg>
	)
}
